import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { RootLayout } from "./views/components/index";
import { HomePage } from "./views/pages";
import DeleteAccountScreen from './views/pages/delete-account';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<RootLayout />}>
          <Route index element={<HomePage />} />
          <Route path="trang-chu" element={<HomePage />} />
        </Route>

        <Route path="/delete-acount" element={<DeleteAccountScreen />} />

      </Routes>

    </Router>
  );
}

export default App;
