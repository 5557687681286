import queryString from "query-string";
import axios from "axios";

export const TOKEN = "token";

export const getFromLocalStorage = (key) => {
  try {
    if (typeof window === "undefined") return null;
    const value = window.localStorage.getItem(key);
    if (!value) return null;
    return value;
  } catch (e) {
    return null;
  }
};

const axiosClient = axios.create({
  baseURL: "http://103.57.222.86:8000",
  // baseURL: "http://localhost:8001",

  timeout: 20000,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  }, // 20s cancel

  paramsSerializer: (params) => {
    return queryString.stringify(params);
  }, //=> 'foo=unicorn&ilike=pizza'
});

axiosClient.interceptors.request.use(
  (config) => {
    const token = getFromLocalStorage(TOKEN);
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    if (token) config.headers = { ...config.headers, ...headers };
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

axiosClient.interceptors.response.use(
  (res) => {
    if (res && res.data) return res.data;
    return res;
  }, // mã trạng thái 2xx
  (err) => {
    if (err.response && err.response.data) return err.response.data;
    return Promise.reject(err);
  }
);

export default axiosClient;
