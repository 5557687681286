import axiosClient from "../../config";

export const register = async (data) => {
  return await axiosClient.post("/auth/register", data);
};

export const deleteAccount = async (data) => {
  return await axiosClient.post("/api/delete", data);
};

export const forgotPwd = async (data) => {
  return await axiosClient.post("/auth/resetpass", data);
};

export const veryCodeApi = async (data) => {
  return await axiosClient.get(`/user/verycode`, { params: data });
};

export const userLogin = async (data) => {
  return await axiosClient.post(`/user/login-app`, data);
};

export const checkUser = async (data) => {
  return await axiosClient.post(`/user/checkuser`, data);
};

export const getListUser = async (data) => {
  return await axiosClient.get(`/user`, { params: data });
};

export const resetPwd = async (data) => {
  return await axiosClient.post(`/user/upresetPassword`, data);
};
